import React from 'react';
import { Link as Anchor } from 'gatsby';
import { rgba } from 'polished';
import PropTypes from 'prop-types';
import { FormattedDate } from '@react-intl';
import styled, { css } from 'styled-components';

import { Item } from 'Components/List';

const Date = styled('time')``;
const Excerpt = styled('p')``;
const Image = styled('img')``;
const Link = styled(Anchor)``;
const Title = styled('h2')``;

const Post = ({ className, date, excerpt, slug, title }) => (
  <Item className={className}>
    <Link to={`/blog/${slug}`}>
      <Image
        alt={title}
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
      />
      <Title>
        {title}
        <FormattedDate
          component={Date}
          datetime={date}
          day="numeric"
          month="long"
          value={date}
          year="numeric"
        />
      </Title>
      <Excerpt>{excerpt}</Excerpt>
    </Link>
  </Item>
);

Post.propTypes = {
  className: PropTypes.string,
  date: PropTypes.string,
  excerpt: PropTypes.string,
  slug: PropTypes.string,
  title: PropTypes.string,
};

const featured = css`
  grid-column: span 2;
  min-height: 40rem;
  position: relative;

  ${Link} {
    border-radius: 0.6rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;
    justify-content: flex-end;
    left: 0;
    padding: 2.4rem;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &::before {
    background-image: linear-gradient(
      360deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }
  
  ${Image} {
    height: 100%;
    left: 0;
    margin: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -2;
  }

  ${Title}, ${Date}, ${Excerpt} {
    color: #ffffff;
    min-width: 100%;
  }
`;

function resize(image, size) {
  return image.replace('/images/', `/images/size/w${size}/`);
}

export default styled(Post).attrs(props => ({ image: props.image || '' }))`
  border-radius: 0.6rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;

  ${Link} {
    display: inline-block;
    padding: 0 1.2rem 1.2rem;
  }

  ${Image} {
    background-image: url("${props =>
      resize(props.image, 300)}"), linear-gradient(69deg, #005dff, #0095ff);
    background-position: center;
    background-size: cover;
    border: 0;
    height: 25.6rem;
    margin: 0 -1.2rem 0.8rem;
    width: calc(100% + 2.4rem);
  }

  ${Date} {
    color: ${rgba('#000f19', 0.4)};
    display: block;
    font-size: 1.2rem;
    margin-top: 0.4rem;
    margin-left: 0.2rem;
    width: 50%;
  }

  ${Title} {
    font-size: 1.8rem;
    line-height: 1.15;
    margin-bottom: 1.2rem;
  }

  ${Excerpt} {
    font-weight: 400;
    line-height: 1.5;
    word-break: break-word;
  }

  @media screen and (min-width: 480px) {
    ${props => props.featured && featured};
    
    ${Image} {
      background-image: url("${props =>
        resize(props.image, 600)}"), linear-gradient(69deg, #005dff, #0095ff);
    }

    ${Title} {
      font-size: 2.4rem;
    }
  }

  @media screen and (min-width: 840px) {
    ${Image} {
      background-image: url("${props =>
        resize(props.image, 1000)}"), linear-gradient(69deg, #005dff, #0095ff);
    }
    
    ${Excerpt} {
      font-size: 1.8rem;
    }
  }
  
  @media screen and (min-width: 1280px) {
    ${Image} {
      background-image: url("${props =>
        resize(props.image, 2000)}"), linear-gradient(69deg, #005dff, #0095ff);
    }
  }
`;
