import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@react-intl';
import styled from '@styled-components';

const Empty = ({ className }) => {
  return (
    <FormattedMessage className={className} component="h1" id="SOON">
      We are coming soon. Please, stay tuned.
    </FormattedMessage>
  );
};

Empty.propTypes = {
  className: PropTypes.string,
};

export default styled(Empty)``;
