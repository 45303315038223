import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from '@prop-types';

import View from 'Containers/Blog';

const Blog = ({
  data: {
    posts: { edges },
  },
}) => {
  let last = false;
  const posts = edges.map(({ node }, index) => {
    const featured = index === 0 || (!last && node.featured);
    const post = {
      ...node,
      category: node.primary_tag && node.primary_tag.name,
      date: node.published_at,
      featured,
      image: node.feature_image,
    };

    last = featured;

    return post;
  });

  return <View posts={posts} />;
};

Blog.propTypes = {
  data: PropTypes.shape({
    posts: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.object),
    }),
  }),
};

export const query = graphql`
  query($pillarAssetsId: String!) {
    posts: allGhostPost(
      sort: { fields: published_at, order: DESC }
      filter: { id: { ne: $pillarAssetsId } }
    ) {
      edges {
        node {
          excerpt
          featured
          feature_image
          published_at
          slug
          title
        }
      }
    }
  }
`;

export default Blog;
