import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import List from 'Components/List';

import Empty from './Empty';
import Post from './Post';

const Blog = ({ className, posts }) => {
  return (
    <section className={className}>
      <List emptyTemplate={Empty} items={posts} noWrapper template={Post} />
    </section>
  );
};

Blog.propTypes = {
  className: PropTypes.string,
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      excerpt: PropTypes.string,
      image: PropTypes.string,
      slug: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
};

export default styled(Blog)`
  margin: 0 auto;
  max-width: 128rem;
  min-height: calc(100vh - 45rem);
  padding: 2.4rem;

  ${List} {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 3.2rem;
  }

  @media screen and (min-width: 480px) {
    min-height: calc(100vh - 35rem);
    padding: 3.2rem 6.4rem;

    ${List} {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media screen and (min-width: 840px) {
    padding: 3.2rem 12.8rem;

    ${List} {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media screen and (min-width: 1280px) {
    padding: 6.4rem 3.2rem;
  }
`;
